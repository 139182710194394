.bigloader{width: 100%; height: 100vh; display: flex; align-items: center; justify-content: center; position: fixed; top:0px; left:0px; z-index: 3434;}

.bigloader .loader {
    position: relative;
    display: flex;
    width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(27.5px) !important;
    justify-content: center;
    align-items: center;
  }
  
  .bigloader .loader>span{transform: scale(3);}