@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{width: 100%; height: 100vh; overflow: hidden;}
.light{background: #E1E1E1; width: 100%; height: 100vh; overflow: hidden;}
*{transition: 0.3s; font-family: 'Montserrat', sans-serif;}
p{margin-bottom: 0;}
.login{width: 100%; height: 100vh;display: flex; align-items: center; flex-direction: column; justify-content: center;}
.video_box{background-color: white; position: absolute; top:20vh; border-radius: 2vw;  width: auto; 
  transition: background-color 0.3s, top 0.7s, left 0.7s, width 0.7s, min-width 0.7s; padding: 1.5vw; display: flex; min-width: 35vw;justify-content: flex-start; }
.test_box_video .video_col{width: 100%;}
.video_text img, .kamera_error img{width:10vw}
.video_box .video{width:100%; display: flex; flex-direction: column; align-items: center; justify-content: center;height: 25vw; overflow: hidden; border-radius: 1.5vw; position: relative;}
.last_text_box_text{height: 30vw !important;}

.video_col{background-color: white; position: relative; z-index: 23; }
.loader{position: absolute; top:0px; left:0px; width: 100%; height: 100%; background: rgba(70, 70, 70, 0.85); display: flex; align-items: center; justify-content: center;}
.video video{width: 35vw;}

.video_text{width: 30vw;  transition: background-color 0.3s, top 0.7s, left 0.7s, width 0.7s, min-width 0.7s; padding:0 1.5vw 1.5vw 1.5vw; display: flex; flex-direction: column; align-items: center; justify-content: center;position: absolute; left:5vw;}
.video_text .video_text_item{width: 100%;height: 1.6vw; margin-top: 1.75vw; display: flex; align-items: center;}
.video_text .user_icon{background-image: url(./image/user_icon.svg);}
.video_text .passport{background-image: url(./image/passport.svg);}
.video_text .tel{background-image: url(./image/tel.svg);}
.video_text .group{background-image: url(./image/group.svg);}
.video_text_img{width: 10vw; height: 1.6vw; background-repeat: no-repeat; background-size: auto 100%; background-position: center center; }
.video_text_item div{width: 15% !important;height: 100%; display: flex; overflow: hidden; align-items: center; justify-content: center;}
.video_text_item img{height: 100%;}
.mini_img img{height: 90%;}
.video_text_item p{font-weight: 600;  font-size: 1.6vw;line-height: 1.8vw;text-align: left;color: #7A878A;}
.show_text{left: 36.5vw;}
.video_text h1{white-space: nowrap;}
.video_text h1, .kamera_error h1{font-weight: 700;font-size: 1.6vw;margin-top: 1vw;line-height: 1.7vw;text-align: center;}
.video_text span,  .kamera_error span{font-weight: 500;font-size: 1vw;width: 16vw;margin-top: 1vw;line-height:1.2vw;text-align: center; color: #7A878A; }

.left_video_text{min-width: 66vw;}
.success h1{color: #70B002;}
.error h1{color: #FF4621; white-space: wrap;}

.test_box_video{padding-bottom: 4vw;padding-top: 3vw; width: 43vw; top:13vh}
.test_box_video:has(.last_text_box_text){top: 6.5vh;}
.test_text_box h1{
  font-weight: 700;
font-size: 2.08vw;
text-transform: uppercase;
line-height: 2.55vw;
color: #70B002;
margin-bottom:2.08vw;
}
.test_text_box p{
  display: flex;
  margin-top:0.83vw;
  margin-bottom:1.14vw;
  width: 100%;
  align-items: center;
 
  font-weight: 700;
font-size: 1.82vw;
line-height: 2.24vw;
text-align: right;
color: #7A878A;
}
.test_text_box span{
  font-weight: 700;
  font-size: 2.34vw;
  margin-left: 1.04vw;
  line-height: 2.86vw;
  color: #70B002;
}
.test_text_box p div{width: 60%;}
.video_box_none{position: absolute; top:100vh !important;}
.video_box_top{position: absolute !important; top:-100vh !important;}
.green_text p{color: #70B002;}
.headerBox{width: 100%; padding:2vw 5vw; align-items: center; display: flex; justify-content: space-between; position: fixed; top:0px; left:0px; z-index: 3455;}
.headerBox img{height: 3.17vw;}
.header_img_main{width: 17.6vw; height: 3.9vw; background-image: url('./image/logo.svg'); background-size: 100% 100%; background-repeat: no-repeat; background-position: center center;}
.header_btns{display: flex; }
.header_btn_item{width:2.6vw; font-weight: 700;
    font-size: 1.14vw; text-transform: uppercase;transition: 0.1s !important;
    line-height: 1.2vw; user-select: none;
    color: #FFFFFF; cursor: pointer; height: 2.6vw; padding: 0.5vw; margin: 0 0.32vw;
    /* transform: scale(1,1); */
    border-radius: 0.7vw; background: #7A878A; display: flex; align-items: center; justify-content: center; }
.header_btn_item img{width: 100%;} 
.center{margin-top: 0vw; width: 100%; padding: 0px 12.5%;
transition: background-color 0.3s, top 0.7s, left 0.7s, width 0.7s, min-width 0.7s; 
  display: flex; position: absolute; top: 0; flex-direction: column; height: 100%; position: relative; align-items: center; justify-content: center;}
.center img{width: 11.3vw;  margin-bottom: 2vw;}
.center p{margin-top: 0vw; font-weight: 400;font-size: 2.08vw;line-height: 2.55vw;text-align: center;color: #7A878A;}
.center_text_main{ height: 15.62vw;}
.center p b{transition: 0s;}
.center_row{display: flex; width: 100%; position: relative; z-index: 23;}
.center_item{width: 50%; display: flex; flex-direction: column; align-items: center; justify-content: flex-start; padding: 0 3.3%;}
.center span{font-weight: 400;font-size: 1.5vw;line-height: 1.6vw;text-align: center;color: #7A878A;}
.back_main{width: 100%; height: 100vh; position: fixed; top: 0px; left: 0px; background-image: url('./image/back_main_white.jpg');
background-size: 100% 100%; background-position: 0 0; opacity: 1; transition: 0.5s;
}
.text_span{text-transform: uppercase; margin-top: 1vw;}
.btn_cam{background: #70B002;border: none;width: 24vw;height: 3.2vw;outline: none;font-weight: 700;font-size: 1.7vw;
  text-decoration: none; transition: 0.1s !important;z-index: 23;
  line-height: 1.5vw;text-align: center;margin-top: 1.4vw;display: flex; align-items: center; justify-content: center;
  color: #FFFFFF;box-shadow: 0px 4px 10px #70B002;border-radius:0.78vw;}
.btn_cam:hover{color: white;}
.btn_cam_sm{width: 25vw;height: 3.2vw;font-size: 1.5vw;}
.btn_warning{background: #FFB501;box-shadow: 0px 4px 10px #FFB501;   }
.footer{position: fixed; height: 4vw; align-items: flex-start; right: 0vw; bottom: 0; width: 100%; display: flex; justify-content: flex-end;}
.footer_box{display: flex; padding: 0.46vw 0.88vw;border-radius: 0.78vw;
  background: #7A878A; margin-right: 5vw; text-decoration: none;}
.footer_img{width:1.5vw; margin-right: 0.5vw; background-image: url(./image/phone_footer.svg);height: 1.4vw; background-repeat: no-repeat; background-size: auto 100%; background-position: center center;  }

.footer b{font-weight: 400;font-size: 1.3vw;line-height: 1vw; color: white; display: flex; align-items: center;}
.main_none .center{top:-100vh}
.main_none .back_main{opacity: 0;}
.main_none .center img{height: 3.17vw; width: auto; top:2vw; left:5vw}
.main_none .center p{opacity: 0;}
.main_none .footer{width: 23vw; right: 0vw;}
.scan{position: relative;display: flex; align-items: center;justify-content: center;flex-direction: column;width: 100%;height: 100%;animation: animateColor 3s linear infinite;}
.scan .face{position: relative;width: 100%;height: 18vw;/* background:url('./image/user.svg') ; */background-repeat: no-repeat;background-position: top center;background-size: auto 18vw;}
.scan .face::before{content: '';position: absolute;top:0px;left:0;width: 100%;height: 100%;background:url('./image/user_line_point.svg') ;background-repeat: no-repeat;background-position: top center;background-size: auto 18vw;
    animation: animate 3s ease-in-out infinite;}

  .faceBox  .scan .face{position: relative;width: 100%;height: 7vw;/* background:url('./image/user.svg') ; */background-repeat: no-repeat;background-position: top center;background-size: auto 7vw;}
.faceBox .scan .face::before{content: '';position: absolute;top:0px;left:0;width: 100%;height: 100%;background:url('./image/user_line_point.svg') ;background-repeat: no-repeat;background-position: top center;background-size: auto 7vw;
    animation: animate 3s ease-in-out infinite;}
    .faceBox .dots img{height: 7vw;}
.select_box{position: relative !important;}
.select_box span{margin-top: 0;}
@keyframes animate{0%, 100%{height: 0%;}
50%{height: 100%;}
}
.scan .face::after{content: '';position: absolute;top:0px;left:20%;width: 60%;height: 0.4vw;background-color: #00FFFF;border-radius: 0.4vw;filter: drop-shadow(0 0 20px #00FFFF) drop-shadow(0 0 3.125vw #00FFFF);animation: animate_line 3s ease-in-out infinite;  }
  @keyframes animate_line{0%, 100%{top: 0vw;}
50%{top: calc(100%);}
}
.scan .face .dots{position: absolute;bottom:0px;display: flex; align-items: flex-end; justify-content: center;overflow: hidden;width: 100%;animation: face_lines 3s ease-in-out infinite;}
.dots img{height: 18vw;}
@keyframes face_lines{0%, 100%{      height: 100%;   }
   50%{      height: 0%;   }
}
@keyframes animateColor{0%{filter:hue-rotate(0deg)}
100%{filter:hue-rotate(360deg)}
}
.btn_test{text-transform: uppercase;}
.video_col{display: flex; flex-direction: column; align-items: center; justify-content: center;}
.test{padding: 1.5vw;}
.faceBox{border-radius: 0.7vw; width: 11.3vw; height: 8.5vw; display: flex; align-items: center; position: relative; justify-content: center;overflow: visible; box-shadow: rgba(60, 64, 67, 0.2) 0px 4px 12px;}
.tooltip_face{width:97vw; height: 31vw;  transition: 0.5s !important;  background-color: white;position: fixed; top:17vw; left:1.5vw; z-index: 1232;border-radius: 0.7vw; color: #FF4621;
  font-size: 1.3vw; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 1vw; transform: scale(0, 0);}
.tooltip_face p{width: calc(100% - 5vw); font-weight: 700; font-size: 1.3vw; text-align: center; margin-top: 2vw; text-transform: uppercase;}
.tooltip_face div{width: 10vw; height: 5vw; background-color: #FF4621; border-radius: 1.5vw; font-size: 3vw;color: white; font-weight: 700; display: flex; align-items: center; justify-content: center;}
.tooltip_face span{text-transform: lowercase; font-weight: 700;}
.tooltip_face_active{transform: scale(1, 1);}

.select_box{margin-top: 1.5vw; width: 25vw; position: relative; display: flex; align-items: center; justify-content: center; flex-direction: column;}
.select_box select{
  appearance: none;
  background: #7A878A; width: 22vw; padding:0.2vw 1vw;border-radius:0.78vw; outline: none; border: #7A878A solid 2px;
  width:100%;
  box-shadow: none !important; outline: none !important;  color:white; font-size: 1.2vw;
}
.select_box:after{content: ''; position: absolute; top:0.8vw; right:1.2vw; width: 1vw; height: 1vw;background-image: url('./image/select_row.svg'); background-position: center center; background-repeat: no-repeat; background-size: 100% auto;}
.select_fixed{ width: 25vw;box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; height: auto; background-color: #7A878A; border-radius:0.78vw;} 
.select_box_div{display: flex; align-items: center; justify-content: center; width: 100vw; height: 100vh; background-color: #ffffff20; backdrop-filter: blur(5px); position: fixed; top: 0; left: 0;} 
.select_box_item{padding:0vw 1vw; overflow: hidden; min-height: 2.2vw; font-size: 1.2vw;  display: flex; align-items: center; width: 100%; color: white; cursor: pointer;}
.select_box_item:last-child{border-radius: 0 0 0.78vw 0.78vw !important;}
.select_box_item:first-child{border-radius: 0.78vw 0.78vw 0 0  !important;}
.select_box_item:hover{background-color: #70B002;}
.select_1{margin-top:3vw}
.form-select:focus{border-color: transparent;}
.form-select:disabled{color: #7A878A !important;}
.select_box:has(.form-select:disabled):after{background-image: url('./image/select_row_disabled.svg')}

.btn_cam:active, .header_btn_item:active{transform: scale(0.9, 0.9) !important;}
.text_error{color: #FF4621 !important; text-transform: uppercase; font-weight: 600 !important; margin-top: 10vw !important;}
.tooltip-arrow, .tooltip-arrow::before{transition: 0s !important;}
.tooltip{font-size: 0.9vw;}
.tooltip-inner{background-color: #7A878A;}
.tooltip-arrow::before{border-bottom-color: #7A878A !important;}
.error_go_test{background-color: #7A878A; box-shadow: 0px 4px 10px #7A878A; cursor: progress !important}
.modal_confirm{width: 100%; border-radius: 50%; overflow: hidden; transition: 0.7s !important; height: 100vh; transform: scale(0, 0); position: fixed; top:0%; left: 0%; z-index: -3; opacity: 0; display: flex; align-items: center; justify-content: center;}
  .modal_confirm_body{width: 63vw; height: 34vw; background: linear-gradient(89.55deg, #D6FFF5 1.77%, #F3FCFF 91.21%, #FFFFFF 108.44%);
    border-radius: 2vw; display: flex;}
  .modal_confirm_back{width: 100%; height: 100%; position: absolute; top: 0px; left:0px; z-index: -1;background-color: rgba(4, 4, 4, 0);
    transition-delay:0.6s;
    backdrop-filter: blur(17.5px);}
  .modal_confirm_open{
    transform: scale(1, 1);border-radius: 0%; opacity: 1;z-index: 3342; 
  }
  .modal_confirm_open .modal_confirm_back{background-color: rgba(4, 4, 4, 0.5);}
    .modal_confirm_img{display: flex; align-items: center; justify-content: flex-end; position: relative; width: 17vw;}
    .modal_confirm .girl{width: 18.5vw; position: absolute; bottom: -5.5vw; left: -2.5vw; }
    .modal_confirm .icon{width: 9.5vw; }
    .modal_confirm_text{display: flex; padding: 0 3vw; flex-direction: column; justify-content: center; align-items: center; width: calc(100% - 25vw);}
    .modal_confirm_right{display: flex; padding: 3vw 3vw 3vw 0; flex-direction: column;  justify-content: space-between; align-items: center; }
   
    .modal_confirm_text h1{font-weight: 900;
      font-size: 2.6vw;
      line-height: 2.7vw;
      color: #ffc355; text-transform: uppercase;}
      
      .modal_confirm_text h2{font-weight: 700;
        font-size: 2.08vw;
        line-height: 2.1vw;
         color: #6B777A; text-transform: uppercase;}
         .modal_confirm_text span{
          margin-bottom: 1.71vw;font-weight: 400;
          font-size: 1.25vw;
          line-height: 1.4vw;
          text-align: right;
          
          color: #7A878A;
         }
      .modal_confirm_text p{font-weight: 700;
        font-size: 1.56vw;
        text-transform: uppercase;
        
        line-height: 1.8vw;
        text-align: center;
        color: #ffc355;}  
        .modal_confirm   .pp{
          text-transform: none !important;
          margin-top: 3vw;
          margin-bottom: 1vw;
          color: #6B777A !important;
        }
        .modal_confirm   .pb{
          display: flex;
          text-transform: none !important;
          margin-top: 2vw;
          margin-bottom: 1vw;align-items: center;
        
        }
        .pb p{  color: #6B777A ; text-align: left;}
        .pb_item{font-size: 2.5vw !important; margin-left: 1vw; color: #e73232 !important;}
.confirm_btns{display: flex; justify-content: space-around; width: 60%;}
.confirm_btns button,  .modal_confirm_right button{width: 8vw;
  height: 3.125vw; background: rgba(0, 0, 0, 0.05); border: none; outline: none;
  backdrop-filter: blur(20px); border-radius: 20px; font-weight: 700;
  font-size: 1.56vw;
  line-height:1.8vw;
  color: #7A878A;}

.modal_confirm .yoq:hover{background-color: #70B00220; color: #70B002;}
.modal_confirm .ha:hover{background-color: #FF462120; color: #FF4621;}
.result_modal .girl{bottom:-2vw; left:-5vw}
.modal_confirm_success h1,.modal_confirm_success p{color: #86D300;}
.modal_confirm_error h1,.modal_confirm_error p{color: #FF4621 !important;}
.result_modal p{margin-bottom: 2vw; text-transform: none;}
.result_modal .confirm_btns{width: 100%;}
.result_modal .confirm_btns button{margin-top: 1vw;}
.modal_confirm .time_btn button{font-weight: 700;
 font-size: 2.08vw;}
.modal_confirm  .time_btn{ margin-top: 2.09vw;}
.modal_confirm .confirm_items{width: 7.08vw;display: flex;
 height: 3.125vw; background: rgba(0, 0, 0, 0.05);
 border-radius: 20px;}
.modal_confirm .confirm_item_color{border-radius: 20px; width: 3.125vw;
 height: 3.125vw;}
.modal_confirm  .confirm_success{background: #86D300;}
.modal_confirm  .confirm_error{background: #F56161;}
.modal_confirm  .confirm_none{background: #B8B8B8;}
.modal_confirm  .confirm_item_number{font-weight: 700;
   font-size: 2.08vw;color: #6B777A;
   display: flex; align-items: center; justify-content: center;width: calc(100% - 3.125vw);
   line-height: 2.2vw;}
.confirm_answer{width: 100%; height: 100vh; position: fixed; top: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); z-index: 6423;justify-content: center; padding-top: 4vw; display: none;}
.confirm_answer_body{width: 30vw; transition: 0.3s; height: 12vw; padding: 2vw 1vw 1.5vw 1vw; background-color: white; border-radius: 1vw; display: flex; flex-direction: column; justify-content: space-between;
  margin-top: -30vw;}
.confirm_btn{display: flex; width: 100%; justify-content: center; }
.confirm_btn button{width: 6vw;
  height: 3vw; background: rgba(0, 0, 0, 0.05); border: none; outline: none;
  backdrop-filter: blur(20px); border-radius: 1.1vw; font-weight: 700;
  font-size: 1.5vw;
  line-height: 2.01vw;margin-right: 0.5vw; margin-left: 0.5vw;
  color: #7A878A;}
  .confirm_btn button:nth-child(1){background-color: #E1E1E1;}
.confirm_btn button:nth-child(2){background-color: #E1E1E1;}
  .confirm_btn button:nth-child(1):hover{background-color: #F56161; color: white;}
  .confirm_btn button:nth-child(2):hover{background-color: #86D300; color: white;}
  .confirm_answer_open{display: flex; }
  .confirm_answer_open .confirm_answer_body{margin-top: 0;}
  .confirm_answer p{font-weight: 700;
    font-size: 1.6vw;
    text-transform: uppercase;
    line-height: 2.01vw;
    text-align: center;
    color: #6B777A;}
.text_error{display: none;}
.loginForm{display: flex; width: 35vw; background-color: white; flex-direction: column; justify-content: space-around; height: 100%; padding:5vw  5vw;}
.loginForm input{outline: none !important; padding: 0.5vw 1vw; color: white; font-weight: 500; font-size: 1.3vw; border-radius: 1vw; background-color: #7A878A; border: none;}
.loginForm input::placeholder{color:#ffffff ; font-size: 1.3vw !important;}
.loginForm input:focus::placeholder{color: #ffffff;}
.hidden_text{opacity: 0 !important;}
.kamera_error{display: flex; align-items: center; justify-content: center; flex-direction: column; width: 35vw; position: relative; }

.kamera_error h1{font-weight: 700;font-size: 1.6vw;margin-top: 1vw; line-height: 1.7vw;text-align: center; color: #FF4621; text-transform: uppercase;}
.kamera_error span{font-weight: 500;font-size: 1.1vw;width: 25vw;margin-top: 1vw;line-height:1.5vw;text-align: center; color: #7A878A; }
.video:has(.kamera_error){height: 30vw;}
.select_disabled{background-color: #aaaaaa !important; border-color: #B8B8B8 !important; cursor: no-drop !important;}
.main_form{
  padding: 0px;
  border-radius: 1.04vw;
  overflow: hidden;
border: 3px solid rgba(255, 255, 255, 0.37);
background: rgba(236, 236, 236, 0.59);
margin-top: 1.3vw;
backdrop-filter: blur(5px);
}
.main_form_head{width: 100%; display: flex; justify-content: space-between;}
.main_form_head_item{width: 50%; cursor: pointer; display: flex;background: #E1E1E1; align-items: center; justify-content: center; padding: 12px;}
.main_form_head_item:nth-child(1){border-radius: 0 0 0.57vw 0;}
.main_form_head_item:nth-child(2){border-radius: 0 0 0 0.57vw;}
.main_form_head_item p{color: #7A878A;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;}
  .main_form_body{
    padding: 1.97vw;
    padding-bottom: 2.34vw;
  }
  .login_icon{
    background-image: url('./image/user_main_disable.svg');
  }
  .telefon_icon{
    background-image: url('./image/phone_main_disable.svg');
  }
  .main_form_img{background-size: auto 100%; margin-right: 0.78vw; height: 1.97vw; width: 1.97vw; background-position: center center; background-repeat: no-repeat;}
.active_main{background-color: transparent;}
.active_main p{color: #70B002;}
.active_main .login_icon{
  background-image: url('./image/user_main.svg');
}
.active_main .telefon_icon{
  background-image: url('./image/phone_main.svg');
}
.main_form_body input{font-size: 1.5vw; padding:0.8vw 1.5vw; width: 100%; border-radius: 1.04vw; border: none; outline: none;}
.main_form_body input::placeholder{font-size: 1.3vw !important;}
.main_form_btn_box{display: flex;}
.main_form_btn_box .btn_cam{
  font-size: 1.5vw;
  font-weight: 400;
  height: auto;
  margin-top: 0;
  padding:0.8vw;
  box-shadow: none;
  }
  .face_detect_icon{background-image: url('./image/face_detect.svg');}
  .main_form_btn_icon{width: 3.1vw; height: 3.1vw; margin-right: 1.66vw; background-size: auto 100%; background-repeat: no-repeat; background-repeat: no-repeat;}
  .main_form_btn_box{display: flex; align-items: center; margin-top: 2.34vw;}
  .btn_cam_dis .btn_cam{
    background: #E1E1E1;
 
  cursor: no-drop;
  color: #7A878A;
  }
  .btn_cam_dis .face_detect_icon{background-image: url('./image/face_detect_disable.svg');}
  .btn_cam_dis .btn_cam:active{transform: scale(1, 1) !important;}
  .back_img_header_dark{display: none;}
  .ant-message-notice-content{padding: 0 !important; border-radius: 0.78vw !important;
    background-color: #E73232 !important;}
  .ant-message-custom-content{display: flex; align-items: center; padding:1.04vw 2.6vw }
  .ant-message-custom-content img{width: 2.65vw; margin-right: 0.52vw;}
  .ant-message-notice-content span{color: #FFF;
    font-size: 25px;
    font-weight: 600;}
    .passwordDiv{margin-top: 0.5vw; overflow: hidden; transition: 0.3s; height: 6vw; position: relative;}
    .passwordDiv button{position: absolute; right: 0px; padding:0.6vw 1.3vw 0.6vw 0.3vw; top: 2.1vw; z-index: 2333; font-size: 1.8vw; 
      background-color: transparent; 
    border:none; outline: none; }
    .passwordDiv button span{color: #7A878A;}
    .disPassword{height: 0px;}
    .passwordDiv input{margin-top: -0.4vw;}
    .passwordDiv p{font-size: 1.1vw; font-weight: 500; width: 100%; text-align: left; padding-left: 1vw;}
    .test_image{position: relative;}
    .test_image:after{content: ""; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;}
  @media(max-width:950px){
  .select_box, .btn_cam, .text_span{display: none;}
  .headerBox img{height: 7vw;}
  .header_btn_item{width: 5vw; height: 5vw; font-size: 2vw;}
  .header_btn_item img{width: 3vw;}
  .center img{width: 35vw;}
  .center p{font-size: 5.5vw; line-height: 5vw; margin-top: 3vw;}
  .text_error{ font-size: 4.5vw; text-align: center; position: fixed; top: 15vw; left:0px; width: 100%; height: 60vh; z-index: 1233; display: flex; align-items: center; justify-content: center;
  padding: 5vw; margin-top: 0 !important;
  }
  .center_row{display: none;}
  .footer img{width: 4vw; }
  .footer{bottom: 7vw;}
  .footer_box{padding: 2vw;}
  .footer_img{width: 3vw;height: 3vw;}
  .footer b{font-size: 3vw !important;}
  .header_img_main{width: 17.11vw !important; height: 9.9vw !important;}
}
@media(max-width:700px){
  .headerBox{z-index: 2344;}
  .headerBox img{height: 12vw;}
  .header_btn_item{width: 8vw; margin-right: 4vw; height: 8vw; font-size: 4vw;}
  .header_btn_item img{width: 5vw;}
  .center img{width: 55vw;}
  .center{width: 80%; z-index: 1;}
  .center p{font-size: 8.5vw; line-height: 7vw; margin-top: 3vw; width: 100%;}
  .text_error{font-size: 5.5vw; height: 70vh;  top: 20vw;}
  .footer img{width: 6vw; }
  .footer{bottom: 10vw;}
  .footer b{font-size: 4.5vw !important;}
  .header_img_main{width: 22.11vw !important; height: 14.9vw !important;}
}
@media screen and (width:800px) and (height:600px)  {
  .headerBox img{height: 7vw;}
  .header_btn_item{width: 5vw; height: 5vw; font-size: 2vw;}
  .header_btn_item img{width: 3vw;}
  .center img{width: 30vw;}
  .select_box, .btn_cam, .text_span{display: flex;}

  .center p{font-size: 2.5vw; line-height: 3vw; margin-top: 2vw; margin-bottom: 5vw;}
.text_span{margin-top: 5vw !important;}
  .text_error{margin-top: 5vw !important; display: none;}
  .footer img{width: 2vw; }
  .footer{bottom: 1vw;}
  .footer b{font-size: 1.5vw !important;}
  .tooltip_face{width:46.8vw; height: 26.2vw;  transition: 0.5s !important;  background-color: white;position: fixed; top:12.05vw; left:-61.7vw; z-index: 3232;border-radius: 0.7vw; color: #FF4621;
    font-size: 1.3vw; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 1vw; transform: scale(0, 0);}
    .tooltip_face_active{transform: scale(1, 1);}
    .video_col{padding-bottom: 1vw;}
    .video_box{margin-top: 10vw;}
}
.test_box_video:has(.danger_h1) span, .danger_h1{color: #FF4621 !important;}
.active_h1{color: #70B002 !important;}
.test_box_video:has(.danger_h1) .btn_cam{background-color: #E73232; box-shadow: 0px 4px 10px #E73232;}
