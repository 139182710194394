.dark{background: #19212D; width: 100%; height: 100vh; overflow: hidden;}
.dark .video_box{background-color: #2A3545;}
.dark .video_col{background-color: #2A3545;}
.dark .video_text_item p{color: white;}
.dark .video_text span{color: white; }
.dark .header_btn_item{background: #2A3545; color: #7A878A;}
.dark .center p{color: white;}
.dark .center span{color: #CCC;}
.dark .select_box select{background: #2A3545; border-color: transparent;}
.dark .form-select:disabled{color: white !important; background: #4e607b !important;}
.dark .select_box:has(.form-select:disabled):after{background-image: url('./image/select_row.svg')}
.dark .loader{background: rgba(0, 0, 0, 0.51); backdrop-filter: blur(4px);}
.dark .test{background-color: #19212D;}
.dark .header_img_main{background-image: url('./image/logo_dark.svg');}
.dark .test_logo{width: 15vw; height: 6.5vw; background-image: url('./image/logo_test_dark.svg'); 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;}
.dark .test_arrow{background-color: #2A3545;}
.dark .test_arrow:hover{opacity: 0.8;}
.dark .test_arrow_left{background-image: url('./image/arrow_left_dark.svg');}
.dark .test_arrow_right{background-image: url('./image/arrow_right_dark.svg');}
.dark .test_number_item{color: white;background: #2A3545;}
.dark .test_arrow:hover,.dark .test_number_item:hover{background: white; color: #2A3545;}
.dark .test_number_error{background: #E73232; color: white;}
.dark .test_number_success{background: #6EA903; color: white;}
.dark .test_number_select{background: #ffffff; color: #2A3545; cursor: auto;}
.dark .test_number_select:hover{background: #ffffff; color: #2A3545; cursor: auto;}
.dark .test_number_error.test_number_select{background: #b62727; color: white; cursor: auto;}
.dark .test_number_error.test_number_select:hover{background: #b62727; color: white; cursor: auto;}
.dark .test_number_success.test_number_select{background: #578503; color: white; cursor: auto;}
.dark .test_number_success.test_number_select:hover{background: #578503; color: white; cursor: auto;}
.dark .test_number_error:hover{background: #b62727; color: white;}
.dark .test_number_success:hover{background: #578503; color: white;}

.dark .test_lang{background: #2A3545; }
.dark .test_lang_item{color: white;}
.dark .test_lang::after{background: white;}
.dark .uz .uz,.dark .ru .ru,.dark .krill .krill{color: #2A3545;}
.dark .dark_item{color: white;background: #2A3545;}


.dark .time h1{color: #FFFFFF;}
.dark .final_btn{background: #2A3545; color: white; }
.dark .test_video_text h1{color: white;}
.dark .test_video_text p{color: white;}
.dark .question_card{background: #ffffff;color: #2A3545;}
.dark .question_card_correct{background: #6EA903; color: #fff;}
.dark .question_card_error{background: #E73232; color: #fff;}
.dark .test_question p{color: white; user-select: none;}
.dark .test_answers_item{background: #2A3545; color: white; user-select: none;}
.dark .select_answer{background-color: white !important;}
.dark .select_answer p{color: #2A3545 !important;}
.dark .error_answer{background: #E73232; color: white;}
.dark .success_answer{background: #6EA903; color: white;}
.dark .test_arrow_left:hover{background-image: url('./image/arrow_left_dark_hover.svg');  background-size: 0.725vw auto;
    background-repeat: no-repeat;
    background-position: center center;}
.dark .test_arrow_right:hover{background-image: url('./image/arrow_right_dark_hover.svg');  background-size: 0.725vw auto;
    background-repeat: no-repeat;
    background-position: center center;}
    .dark .tooltip_face{background-color: #19212D; color: white;}
.dark .video_text .user_icon{background-image: url(./image/user_icon_dark.svg);}
.dark .video_text .passport{background-image: url(./image/passport_dark.svg);}
.dark .video_text .tel{background-image: url(./image/tel_dark.svg);}
.dark .video_text .group{background-image: url(./image/group_dark.svg);}
.dark .select_fixed{background-color: #2A3545;}
.dark .footer b{color: white;}
.dark .footer_img{background-image: url(./image/phone_footer_dark.svg);}
.dark .test_text_box p{color: white;}
.dark .test_answers_box p{color: white;}
.dark .A006ecb{background-color: #006ecb; }
.dark .A006ecb h1{color: white; }
.dark .chiqish{background-color: #006ecb;color: white; text-transform: uppercase;font-size: 1.8vw;}
body:has(.dark) .tooltip-inner{background-color: #2A3545 !important;}
body:has(.dark) .tooltip-arrow::before{border-bottom-color: #2A3545 !important;}
.dark .loginForm{background-color: #2A3545;}
.dark .loginForm input{color: white; background-color: #19212d; border: none;}
.dark .loginForm input::placeholder{color:white;}
.dark .loginForm input:focus::placeholder{color: white;}
.dark .kamera_error span{color: white; }
.dark .back_main{background-image: url('./image/back_main_dark.jpg');}
.dark .footer_box{background: #2D3745;}
.dark .footer b{color: #7A878A;}
.dark .main_form{
border: 3px solid #414C5C;
background: #2D3745;
}
.dark .main_form_head_item{background: #414C5C;}
.dark .active_main{background-color: transparent;}
.dark .main_form_head_item p{color: #7A878A;}
.dark .active_main p{color: #70B002;}
.dark .face_detect_icon{background-image: url('./image/face_detect_dark.svg');}
.dark .btn_cam_dis .face_detect_icon{background-image: url('./image/face_detect_disable_dark.svg');}
.dark .btn_cam_dis .btn_cam{background: #414C5C;}
.dark .back_img_header_dark{display: flex;}
.dark .back_img_header{display: none;}
.dark .passwordDiv button span{color: #7A878A;}
    @media screen and (width:800px) and (height:600px)  {
        .dark .test_logo{width: 11.5vw; height: 4.2vw;order: 1;}
        .dark .time{background: #2A3545}
        .dark .test_arrow_left{background-image: url('./image/arrow_left_sm_dark.svg');}
.dark .test_arrow_right{background-image: url('./image/arrow_right_sm_dark.svg');}
        .dark .test_arrow_left:hover{background-image: url('./image/arrow_left_sm_dark_hover.svg');  background-size: 2.7vw auto;
            background-repeat: no-repeat;
            background-position: center center;}
        .dark .test_arrow_right:hover{background-image: url('./image/arrow_right_sm_dark_hover.svg');  background-size: 2.7vw auto;
            background-repeat: no-repeat;
            background-position: center center;}
           .dark .test_number_select{background: #FFB501; color: white; cursor: auto;}
.dark .test_number_select:hover{background: #FFB501; color: white; cursor: auto;}

    }