@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka&display=swap');
body {margin: 0;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;}
.test{width: 100vw; height: 100vh; overflow: hidden; background-color: white; display: flex; 
flex-wrap: wrap; justify-content: space-between; padding: 2.2vw; padding-right: 0.6vw !important;}
.test *{user-select: none !important;}
.test_logo{width: 15vw; height: 6.5vw; background-image: url('./image/logo_test.svg'); 
background-repeat: no-repeat;
background-position: center center;
background-size: 100% 100%;}
.test_logo img{width: 100%;}
.test_number{width: 39vw; display: flex; height: 6.5vw;}
.test_number_box{display: flex; width: 100%; justify-content: space-between; flex-wrap: wrap; align-items: center;}
.test_header_params{width: 16.5vw; display: flex;height: 6.5vw; flex-wrap: wrap;justify-content: flex-start; align-content: space-between;}
.test_header_params div{margin: 0 0.27vw;}
.finished_test_header{justify-content: space-between;}
.finished_test_header div{margin: 0 0vw;}
.test_video{width: auto;height: 6.5vw; padding-right: 1.2vw}
.test_arrow{background: #E1E1E1; opacity: 1;transition: 0.2s !important;background-size: 0.725vw auto;background-repeat: no-repeat;background-position: center center;backdrop-filter: blur(20px);width:2.5vw;
    height: 6.5vw;margin: 0 0.66vw;cursor: pointer; border-radius: 0.8vw;}
.test_arrow:hover{opacity: 0.8;}
.test_arrow_left{background-image: url('./image/arrow_left.svg');}
.test_arrow_right{background-image: url('./image/arrow_right.svg');}
.test_number_item{width: 3vw; height: 3vw; font-weight: 700;font-size: 1.3vw;transition: 0.1s !important;cursor: pointer;line-height: 1.5vw;color: #7A878A;user-select: none;background: #E1E1E1;backdrop-filter: blur(20px);border-radius: 1vw; display: flex; align-items: center; justify-content: center;}
.test_arrow:hover, .test_number_item:hover{background: #7A878A; color: white;}
.test_arrow_left:hover{background-image: url('./image/arrow_left_dark.svg');background-size: 0.725vw auto;background-repeat: no-repeat;background-position: center center;}
.test_arrow_right:hover{background-image: url('./image/arrow_right_dark.svg');background-size: 0.725vw auto;background-repeat: no-repeat;background-position: center center;}
.test_number_error{background: #E73232; color: white;}
.test_number_success{background: #6EA903; color: white;}
.test_number_select{background: #7A878A; color: white; cursor: auto;}
.test_number_select:hover{background: #7A878A; color: white; cursor: auto;}
.test_number_error.test_number_select{background: #b62727; color: white; cursor: auto;}
.test_number_error.test_number_select:hover{background: #b62727; color: white; cursor: auto;}
.test_number_success.test_number_select{background: #578503; color: white; cursor: auto;}
.test_number_success.test_number_select:hover{background: #578503; color: white; cursor: auto;}
.test_number_error:hover{background: #b62727;}
.test_number_success:hover{background: #578503;}
.test_lang{width: 12vw;height: 3vw;display: flex; background: #E1E1E1;backdrop-filter: blur(20px);position: relative; border-radius: 1vw;}
.test_lang_item{width: 4vw; height: 100%; display: flex; align-items: center; justify-content: center;font-weight: 700;font-size: 1.3vw;user-select: none;line-height: 1.5vw;color: #7A878A; position: relative; z-index: 232;cursor: pointer;}
.test_lang::after{content: ''; width: 4vw; z-index: 0; height: 100%; position: absolute; top:0px; left:0px; background: #7A878A;border-radius: 1vw; transition: 0.3s;}
.uz::after{left:0px}
.ru::after{left:8vw;}
.krill::after{left:4vw}
.uz .uz, .ru .ru, .krill .krill{color: white;}
.dark_item{width: 4vw; height: 3vw; display: flex; align-items: center; justify-content: center;font-weight: 700;font-size: 1.3vw;user-select: none;background: #E1E1E1;line-height: 1.5vw; border-radius: 1vw;color: #7A878A; position: relative; z-index: 232;cursor: pointer;transition: 0.1s !important;}
.dark_item img{width: 1.9vw;}
.time{width:7.5vw; position: relative; height: 3vw;background: #6EA903;backdrop-filter: blur(20px);border-radius: 1vw; display: flex; align-items: center; padding-top: 0.15vw; justify-content: center; }
.time h1{font-weight: 400;font-size: 2vw;user-select: none;margin-bottom: 0px;display: flex;line-height: 2.1vw;font-family: 'Russo One', sans-serif !important; color: #FFFFFF;}
.time span{width: 1.3vw; display: flex; justify-content: center;}
.final_btn{width: 8.5vw;height: 3vw;display: flex; align-items: center; justify-content: center;font-weight: 700;font-size: 1.3vw;user-select: none;background: #E1E1E1;line-height: 1.5vw; border-radius: 1vw;
    text-transform: uppercase; color: #7A878A; position: relative; z-index: 232;cursor: pointer;transition: 0.1s !important; }
.test_video_cam{width: 100%; height: 8.5vw; display: flex; align-items: center; justify-content: center; overflow: hidden; border-radius: 1vw;}
.test_video_text h1{ font-weight: 700; font-family: 'Fredoka', sans-serif !important;font-size: 1.3vw;margin-top: 0.57vw;line-height: 1.1vw;text-align: right;color: #7A878A;}
.test_video_text p{ font-weight: 400; font-family: 'Fredoka', sans-serif !important;font-size: 1.2vw;line-height: 1.1vw;text-align: right;color: #7A878A;}
.test_question{width: 80vw; display: flex; margin-top: 3vw; margin-bottom: 2vw; align-items: center;
 height: 4vw; flex-wrap: wrap;}
.question_card{background: #7A878A;font-weight: 700; display: flex; align-items: center; justify-content: center;font-size: 1.5vw;line-height: 2vw;color: #FFFFFF;border-radius: 1vw;width: 3vw; height: 3vw;}
.question_card_correct{background: #6EA903;}
.question_card_error{background: #E73232;}
.test_question p{font-weight: 700; width: calc(100% - 4vw) !important; font-size: 1.5vw;line-height: 2vw;margin-left: 1vw;color: #6B777A;}
.test_image{display: flex; border-radius: 1.3vw;justify-content: center; align-items: flex-start; width:55vw; height: 30.34vw; overflow: hidden;}
.test_image img{max-width: 100%; min-height: 50%; max-height: 100%;  border-radius: 1.3vw;}
.test_answers{width:42.9vw;padding-left: 1.2vw; padding-right: 0.3vw; height: calc(100vh - 17.5vw); overflow-y: auto; overflow-x: hidden;}
.test_answers_box{width: 40.5vw;; display: flex; align-items: center;  margin-bottom: 1.3vw;} 
.test_answers_box>p{font-weight: 700;font-size: 1.5vw;line-height: 2vw;margin-right: 1vw;color: #6B777A; transition: 0s !important; white-space: nowrap;}
.test_answers_item{width: 100%; height: auto; background: #E1E1E1;backdrop-filter: blur(20px); cursor: no-drop; border-radius: 1.3vw; padding: 0.7vw 1.4vw;font-weight: 500; font-size: 1.14vw; user-select: none; min-height: 4.6vw; display: flex; align-items: center; line-height: 1.3vw;transition: 0.1s !important; color: #383838;}
.error_answer{background: #E73232; color: white;  transition: 0s !important;}
.success_answer{background: #6EA903; color: white;  transition: 0s !important;}
.t_a{cursor: pointer;}
.t_a:active{transform: scale(0.9, 0.9);}
.p_a{transform: scale(0.9, 0.9) !important;}
.test_number_item:active, .test_arrow:active, .final_btn:active, .dark_item:active{transform: scale(0.9, 0.9);}
.test_number_select:active{transform: scale(1,1) !important;}
::-webkit-scrollbar {width: 0.5vw;}
/* Track */::-webkit-scrollbar-track {background: #E6E6E6;border-radius: 0.5vw;}
/* Handle */::-webkit-scrollbar-thumb {background: #B7B7B7;border-radius: 0.5vw;}
/* Handle on hover */::-webkit-scrollbar-thumb:hover {background: #9c9c9c; }
.time_bg{display: none;}
.FFB501{background-color: #FFB501;}
.E73232{background-color: #E73232;}
.A6EA903{background-color: #6EA903;}
.test_answers_item p{transition: 0.1s !important;}
.select_answer{background-color: #7A878A !important; color: white;}
.A006ecb{background-color: #006ecb; }
.A006ecb h1{color: white; }
.chiqish{background-color: #006ecb;color: white; text-transform: uppercase;font-size: 1.8vw;}
.select_btn_error{background-color: #006ecb !important; color: white !important;}
.select_btn_success{background-color: #006ecb !important; color: white !important;}
.images_finish{position: absolute !important; top: 0px; left:0px; z-index: 2456; width: 100%; height: 100%; }
.images_finish img, .carousel, .carousel-inner, .carousel-item{height: 100%;}
.images_finish img{width: auto;}
.carousel-indicators{margin: 0.3vw !important;}
@media screen and (width:800px) and (height:600px)  {
.test_logo{width: 11.5vw; height: 4.2vw;order: 1;}
.test{padding: 2.5vw !important; padding-right: 1.5vw !important;}
.time{position: absolute; top: 2.5vw; left: 25%; width: 28.8vw; height: 4.07vw; justify-content: flex-start; 
padding-left: 1.3vw; align-items: center;line-height: 2.6vw; padding-top: 0.5vw;background: #E1E1E1;
backdrop-filter: blur(20px); 
}
.time h1{font-size: 2.61vw;position: relative; z-index: 3;}
.time span{display: flex;  align-items: center; width: 1.6vw; text-align: center;}
.test_video{order: 3;}
.test_question{order: 4;}
.test_image{order: 5;}
.test_number{order: 6;}
.test_header_params{order: 7;}
.test_answers{order: 8;}
.time_bg{position: absolute; display: block; top: 0px; left: 0px;border-radius: 1vw; height: 100%;}
.test_video{display: flex; height: 11.4vw; width: auto;}
.test_video_cam{order: 2;margin-left: 3vw; overflow: visible; margin-top: 1vw; margin-right: 1.6vw; transform: scale(1.3, 1.3);}
.test_video_text{width: auto;}
.test_video_text h1{font-size:1.56vw; white-space: nowrap;}
.test_video_text p{font-size:1.25vw}
.test_question{margin-top: -4vw; width: 80%; height: 6vw;}
.question_card{width: 4.07vw !important; 
font-size: 1.6vw !important;
height: 4.07vw !important; border-radius: 1vw !important;}
.test_question p{font-size: 1.6vw; width: calc(100% - 7.5vw) !important; margin-left: 1.5vw; }
.test_image{width:62vw; height: 34vw;}
.test_number{width: 32.3vw; display: flex; flex-wrap: wrap;}
.test_number_box{width: 100%; }
.test_number_item, .test_arrow{width: 5vw;font-size: 2.15vw; margin-left: 0px; border-radius: 1vw; height: 5vw; margin-right: 1.2vw; margin-bottom:1.2vw;}
.test_number_select{background: #FFB501; color: white; cursor: auto;}
.test_number_select:hover{background: #FFB501; color: white; cursor: auto;}
.test_arrow_left{order: 2; }
.test_arrow{background-size: 2.5vw auto;}
.test_arrow_right{order: 3; margin-left: 0.3vw;}
.test_arrow_left{background-image: url('./image/arrow_left_sm.svg');}
.test_arrow_right{background-image: url('./image/arrow_right_sm.svg');}
.test_arrow_left:hover{background-image: url('./image/arrow_left_sm_dark.svg');background-size: 2.7vw auto;background-repeat: no-repeat;background-position: center center;}
.test_arrow_right:hover{background-image: url('./image/arrow_right_sm_dark.svg');background-size: 2.7vw auto;background-repeat: no-repeat;background-position: center center;}
.test_lang{position: absolute; right: 2.5vw; top:42.8vw; width: 18vw; height: 5vw;}
.test_lang_item{width: 5vw; font-size: 2vw;}
.test_lang:after{width: 5vw;}
.test_lang .krill{margin: 0 1.45vw;}
.uz::after{left:0px}
.ru::after{left:12.9vw;}
.krill::after{left:6.45vw}
.dark_item{position: absolute; width: 4.17vw; height: 3vw; bottom:2.5vw; right: 2.5vw;}
.final_btn{position: absolute; bottom:2.5vw; right: 8.5vw; height: 3vw; border-radius: 1vw;}
.test_answers{width: 100%; flex-wrap: wrap; display: flex; flex-direction: row; height: 16vw; margin-bottom: 5vw; padding-left: 0px !important;
margin-top: -5vw; overflow-y: auto;}
.test_answers_box{width: 48.6%;}
.test_answers_box:nth-child(even)>p{margin-left: 1vw;}
.test_answers_item{width: 100%; font-size:1.4vw ; line-height: 1.5vw;}
.test_answers_item:nth-child(even){margin-left: 1%;}
.test_answers_item:nth-child(odd){margin-right: 1%;}

}
